body {
    background-color: silver;
    margin: 0;
}

@font-face {
    font-family: "adelle-sans-bold";
    src: url("./fonts/adelle-sans/AdelleSans-Bold.woff2") format("woff2"),
    url("./fonts/adelle-sans/AdelleSans-Bold.woff") format("woff");
}

@font-face {
    font-family: "adelle-sans-regular";
    src: url("./fonts/adelle-sans/AdelleSans-Regular.woff2") format("woff2"),
    url("./fonts/adelle-sans/AdelleSans-Regular.woff") format("woff");
}

@font-face {
    font-family: "adelle-sans-semibold";
    src: url("./fonts/adelle-sans/AdelleSans-Semibold.woff2") format("woff2"),
    url("./fonts/adelle-sans/AdelleSans-Semibold.woff") format("woff");
}

@font-face {
    font-family: "adelle-sans-italic";
    src: url("./fonts/adelle-sans/AdelleSans-Italic.woff2") format("woff2"),
    url("./fonts/adelle-sans/AdelleSans-Italic.woff") format("woff");
}

@font-face {
    font-family: "gt-pressura-bold";
    src: url("./fonts/gt-pressura/GT-Pressura-Bold.woff2") format("woff2"),
    url("./fonts/gt-pressura/GT-Pressura-Bold.woff") format("woff");
}

@font-face {
    font-family: "gt-pressura-regular";
    src: url("./fonts/gt-pressura/GT-Pressura-Regular.woff2") format("woff2"),
    url("./fonts/gt-pressura/GT-Pressura-Regular.woff") format("woff");
}

:root {

    /* Colors: */
    --flame-5-lighter: #FC4E30;
    --flame-25-darker: #AF0100;
    --flame-15-darker: #C91B00;
    --flame-5-darker: #E23416;
    --river-water-25-lighter: #CAE8FF;
    --river-water-15-lighter: #B0CEFF;
    --river-water-5-lighter: #97B5FC;
    --river-water-25-darker: #4A68AF;
    --river-water-15-darker: #6482C9;
    --river-water-5-darker: #7D9BE2;
    --lightning-5-lighter: #FFD80D;
    --lightning-15-darker: #D9A500;
    --lightning-5-darker: #F2BE00;
    --open-sky-15-lighter: #48E2DB;
    --open-sky-5-lighter: #2FC9C2;
    --open-sky-25-darker: #007C75;
    --open-sky-15-darker: #00968F;
    --open-sky-5-darker: #15AFA8;
    --dark-open-sky-25-lighter: #42BBB5;
    --dark-open-sky-15-lighter: #28A19B;
    --dark-open-sky-5-lighter: #0F8882;
    --dark-open-sky-5-darker: #006E68;
    --dark-open-sky-15-darker: #00554F;
    --dark-open-sky-25-darker: #003B35;
    --dark-riverbed-50-lighter: #7FA4BA;
    --dark-riverbed-25-lighter: #40657B;
    --dark-riverbed-15-lighter: #264B61;
    --dark-riverbed-5-lighter: #0D3248;
    --dark-riverbed-5-darker: #00182E;
    --dark-riverbed-15-darker: #000015;
    --dark-riverbed-20-darker: #000008;
    --riverbed-50-lighter: #82C6E3;
    --riverbed-25-lighter: #4387A4;
    --riverbed-15-lighter: #296D8A;
    --riverbed-5-lighter: #105471;
    --riverbed-5-darker: #003A57;
    --riverbed-15-darker: #00213E;
    --riverbed-25-darker: #000724;
    --river-water: #8AA8EF;
    --danger-red: #A80000;
    --warning-tangerine: #E07600;
    --info-blue: #1A696C;
    --success-green: #0A5E0A;
    --flame: #EF4123;
    --lightning: #FFCB00;
    --dark-open-sky: #027B75;
    --open-sky: #22BCB5;
    --dark-riverbed: #00253B;
    --riverbed: #034764;
    --ns-gray-3: #B5ADA5;
    --ns-gray-2: #D2D2CD;
    --ns-gray-1: #E7E7E2;
    --white: #FFFFFF;
    --warm-near-black: #40403C;
    --ns-black: #292625;

    /* Font/text values */
    --unnamed-font-style-normal: normal;
    --unnamed-font-style-italic: italic;
    --unnamed-font-weight-600: 600px;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-size-10: 10px;
    --unnamed-font-size-12: 12px;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-24: 24px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-0-24: 0.24px;
    --unnamed-character-spacing-0-2: 0.2px;
    --unnamed-line-spacing-12: 12px;
    --unnamed-line-spacing-13: 13px;
    --unnamed-line-spacing-15: 15px;
    --unnamed-line-spacing-16: 16px;
    --unnamed-line-spacing-17: 17px;
    --unnamed-line-spacing-18: 18px;
    --unnamed-line-spacing-19: 19px;
    --unnamed-line-spacing-21: 21px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-23: 23px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-line-spacing-29: 29px;
    --unnamed-line-spacing-31: 31px;
}

/* Character Styles */
.pointer {
    cursor: pointer;
}
